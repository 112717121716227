import React from "react"
import { Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

const useStyles = makeStyles(theme => ({
  outerCtr: {
    padding: "5.25em 0",
  },
  h1: {
    paddingTop: '2rem',
    fontSize: "2.9375rem",
    fontWeight: 400,
    // color: theme.palette.common.muted,
    color: theme.palette.secondary.light,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.25rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },
  hr: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: "40%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "2.5rem",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    borderColor: theme.palette.secondary.light,
  },
  postCtr: {
    paddingTop: "2em",
    maxWidth: "40%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "50%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
  },
  postBody: {
    maxWidth: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    lineHeight: "2rem",
    fontSize: "1.05rem",
    "& h2": {
      paddingBottom: ".05rem",
      color: theme.palette.secondary.light,
    },
    '& h3': {
      paddingBottom: '1rem',
      color: theme.palette.secondary.light,
      fontWeight: 400,
    },
    '& blockquote': {
      '& p': {
        color:theme.palette.secondary.light,
        fontWeight: 600,
      },
      paddingBottom: '.5rem',
    },
    "& img": {
      marginTop: "3em",
      marginBottom: "3em",
      boxShadow: "0 12px 64px 0 rgb(0 0 0 / 8%)",
      borderRadius: 20,
      maxWidth: "-webkit-fill-available",
      display: "flex",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  backBtn: {
    fontFamily: "Montserrat, Helvetica Neue",
    fontSize: "1.05rem",
    padding: ".5em 1em",
    marginTop: "2em",
    marginLeft: "1em",
    transition: "0.5s",
    "&:hover": {
      fontWeight: 600,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.muted,
      boxShadow: "0 12px 24px 0 rgb(0 0 0 / 14%)",
    },
  },
}))

export default function ProjectTemplate({ data }) {
  const classes = useStyles()
  // const { title, projectImage } = data.contentfulCustomerTestimonialPage
  const { title, projectImage } = data.contentfulCustomerTestimonialPage
  const body =
    data.contentfulCustomerTestimonialPage.longFormDescription.childMarkdownRemark.html
  const pathToImage = getImage(projectImage)
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      className={classes.outerCtr}
    >
      <Grid item xs={12} lg={6}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            align="center"
            className={classes.h1}
            gutterBottom
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.hr} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <GatsbyImage image={pathToImage} alt={title} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Grid container justifyContent="center" className={classes.postCtr}>
          <div
            variant="body1"
            className={classes.postBody}
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          ></div>
          <Divider />
          <Grid item xs={12}>
            <Typography
              variant="body1"
              component={Link}
              to="/about/"
              style={{ color: '#da7235' }}
            >
              Read more about our company here.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              component={Link}
              color="secondary"
              to="/testimonials/"
              className={classes.backBtn}
            >
              <ArrowBackIosIcon />
              Back to All Testimonials
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
