import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { navigate } from "@reach/router"

import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"
import TestimonialTemplate from "../../components/TestimonialTemplate"
import CallToAction from "../../components/CallToAction"

export default function SingleTestimonialPage({ data }) {
  const { separatePage, title } = data.contentfulCustomerTestimonialPage
  useEffect(() => {
    if (separatePage !== true) {
      navigate("/testimonials/")
    }
  })

  return (
    <Layout>
      <Seo title={title} />
      <Navbar />
      <TestimonialTemplate data={data} />
      <CallToAction end={true} />
    </Layout>
  )
}

export const query = graphql`
  query getSingleTestimonial($url: String) {
    contentfulCustomerTestimonialPage(url: { eq: $url }) {
      title
      id
      separatePage
      longFormDescription {
        childMarkdownRemark {
          html
        }
      }
      projectImage {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          width: 800
          layout: CONSTRAINED
        )
      }
    }
  }
`
